import React from "react";
import Header from './Header/Index';
import { Container } from './styles';
import HomeContent from './HomeContent/Index';

const Index = () => {

  return (
    <> 
      <Container>
        <Header />
        <HomeContent />
      </Container>  
    </>
  );
};

export default Index;
