import React from 'react'
import styled from 'styled-components'
import './styles.css'

type InputProps = {
    criaCodigo: (pos: string, numero: string) => void
    pos: string,
    value: string
    refe?: React.RefObject<HTMLInputElement> | null | undefined
}

const InputCode = React.forwardRef<HTMLButtonElement, InputProps>((props, refs) => {
    
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const numero = e.target.value ? e.target.value : ''
        if(e.target.validity.valid){
            props.criaCodigo(props.pos, numero)
        }
    }
    return (
      <Input ref={props.refe}  type="text" value={props.value} pattern="[0-9]" onChange={handleChange}/>
    )
})

const Input = styled.input`
    display: flex;
    text-align: center;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.16);
    
    border-radius: 4px;
    width: 88px;
    height: 56px;
    
    font-size: 1rem;
    line-height: 1.5rem;
    margin-right: 16px;

    &:focus {
        outline: 2px solid var(--primary);
    }
  
`

export default InputCode

