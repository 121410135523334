import styled from 'styled-components'
import apple from '../../assets/Apple_logo.png'
import google from '../../assets/Google_icon.png'

export const Container = styled.div`
  background: var(--background);
  width: 100vw;
  height: 100vh;
`

export const Content = styled.div`
  max-width: 1366px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 5rem;

  header {
    margin-top: 45px;
  }

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-image {
      margin-left: 40px;
    }

    .information {
      height: 351px;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      margin-right: 165px;
      text-align: center;

      h1 {
        font-size: 1.75rem;
        line-height: 2.5rem;
        font-weight: 600;
        color: var(--primary);
      }

      p {
        font-size: 1rem;
        font-weight: normal;
        color: var(--life-neutral-black-64);
        margin: 2rem 0;
      }

      .btn-apple {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--background);
        border: 2px solid var(--primary) !important;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        /* padding: 12px 24px; */
        border-radius: 64px !important;
        margin-bottom: 1rem;
        cursor: pointer;
        box-shadow: none !important;
        font-size: 1rem !important; 
        color: var(--life-neutral-black-88);
        font-weight: 600 !important;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 1.8rem;
          height: 1.8rem;
          background-image: url(${apple});
          background-position: center;
          background-repeat: no-repeat, repeat;
          top: 15%;
          left: 22%;
        }

        svg {
          display: none;
        }
      }

      .btn-login {
        background-color: var(--background);
        border: 2px solid var(--primary) !important;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        /* padding: 12px 24px; */
        border-radius: 64px !important;
        margin-bottom: 1rem;
        cursor: pointer;
        box-shadow: none !important;
        font-size: 1rem !important; 
        color: var(--life-neutral-black-88);
        font-weight: 600 !important;
        position: relative;

        span {
          display: inline-block;
          padding-left: 6px;
        }

        &:after {
          content: '';
          position: absolute;
          width: 1.8rem;
          height: 1.8rem;
          background-image: url(${google});
          background-position: center;
          background-repeat: no-repeat, repeat;
          top: 19%;
          left: 22%;
          /* background-color: yellow; */
        }
      }
    }
  }

  @media (max-width: 1160px) {
    padding: 0 14px;

    header {
      margin-top: 24px;
      margin-bottom: 6px;
    }
    .main {
      flex-direction: column;
      justify-content: center;

      .content-image {
        margin: 0px;
        margin-bottom: 36px;
        img {
          height: 160px;
        }
      }

      .information {
        text-align: center;
        margin: 0px;


        h1 {
          font-weight: bold;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: var(--primary-dark);
        }
      }
    }
  }

`
