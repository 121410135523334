import React from "react";
import Button from "../../../components/Button/Button";
import { Container, Titulo, Texto, TextoInfo } from "./styles";
import Input from "../../../components/Input/Index";
import { useUserContext } from "contexts/UserContext";

const FormRightEmail = () => {
  const [email, setEmail] = React.useState<string>("");

  const [error, setError] = React.useState<string | null>(null);

  const { authWithEmail, loading } = useUserContext();

  const handleEmail = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (error) validateEmail(e.target.value); //so valida se o usuario tiver errado uma primeira vez.. e sempre fica validando
    setEmail(e.target.value);
  };

  const handleSubmitEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      //  onboarding()
      // conectarEmail(email)
      authWithEmail(email);
      // navigate('/code')
    } else {
      setError("E-mail inválido.");
    }
  };

  const validateEmail = (value: string) => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (value.length === 0) {
      setError("Preencha o campo e-mail.");
      return false;
    } else if (!regex.test(value)) {
      setError(
        "E-mail inválido. Confira o que foi digitado e tente mais uma vez."
      );
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => {
    validateEmail(e.target.value);
  };

  return (
    <form onSubmit={handleSubmitEmail}>
      <Container>
        <Titulo>PASSO 1</Titulo>
        <Texto>Digite seu e-mail para continuar</Texto>
        <TextoInfo>
          Informações sobre suas consultas, prescrições e pedidos serão enviadas
          para o contato escolhido
        </TextoInfo>
        
        <Input
          label="E-mail"
          id="checkEmail"
          handleBlur={(e) => handleBlur(e)}
          placeholder="Entrar com email"
          value={email}
          onChange={handleEmail}
          errorText={error}
        />
        

        {!loading ? (
          <Button
            type="submit"
            variant="primary"
            disabled={email.length > 0 ? false : true}
          >
            Continuar
          </Button>
        ) : (
          <Button type="submit" variant="primary" disabled={true}>
            Enviando
          </Button>
        )}
      </Container>
    </form>
  );
};

export default FormRightEmail;
