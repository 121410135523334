import styled from 'styled-components'
import * as CheckboxStyles from '../../../components/Checkbox/styles'

export const Container = styled.div`
height: 400px;
max-width: 400px;
display: flex;
flex-direction: column;
/* margin-right: 165px; */

div.contentCheckbox {
  margin-top: 26px;
  margin-bottom: 24px;

  > ${CheckboxStyles.CheckboxLife} + ${CheckboxStyles.CheckboxLife} {
    margin-top: 20px;
  }
}
`

export const Titulo = styled.h1`
font-size: 23.5px;
font-weight: 600;
font-family: 'Montserrat', sans-serif;
font-size: normal;

@media (max-width: 800px) {
  font-size: 1.125rem;
  font-weight: bold;
}
`

export const TextoInfo = styled.p`
font-size: 14px;
line-height: 16px;
color: rgba(0, 0, 0, 0.64);
margin-top: 1rem;
margin-bottom: 32px;
`

