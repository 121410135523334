import React from "react";
import AnyConsult from './AnyConsult';
import ExamCard from './ExamCard';
import JourneyCard from './JourneyCard';
import LevelCard from './LevelCard';
// import NewConsult from './NewConsult';
import { ContentHome, Title } from './styles';
// import { useHomeContext } from './../../../contexts/HomeContext';

const HomeContent = () => {
  // const { hasNextConsult, hasAvailableConsults, email, token } = useHomeContext();
  
  // React.useEffect(()=> {
  //   hasAvailableConsults(email, token)
  // }, [hasAvailableConsults]);
  
  return (
    <>
      <ContentHome>
        <div className='main'>
          <LevelCard />

          <div className="sectionJourney">
            <Title>Jornadas do Cuidado</Title>          
            <section> 
              <JourneyCard />
              {/* <SlideExemplo /> */}
            </section>

            <section >
              <Title>Aproveite nossos serviços!</Title>
              <ExamCard />
            </section>
          </div>
        </div>

        <div className="schedule">
          <div className='headerConsult'>
            <Title>Seus agendamentos</Title>
            <a href='#'>Ver histórico</a>
          </div>

          {/* <NewConsult /> */}
          <AnyConsult />

        </div>
      </ContentHome>
    </>
  );
};

export default HomeContent;
