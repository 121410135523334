const IconWarning = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99991 0C11.0999 0 11.9999 0.6 12.5999 1.5L19.5999 13.5C20.0999 14.4 20.1999 15.6 19.5999 16.5C19.0999 17.4 18.0999 18 16.9999 18H2.99991C1.89991 18 0.899902 17.4 0.399902 16.5C-0.100098 15.6 -0.100098 14.4 0.399902 13.5L7.3999 1.5C7.9999 0.6 8.89991 0 9.99991 0ZM9.19991 2.5L2.19991 14.5C1.99991 14.8 1.99991 15.2 2.19991 15.5C2.39991 15.8 2.69991 16 3.09991 16H17.0999C17.4999 16 17.7999 15.8 17.9999 15.5C18.1999 15.2 18.1999 14.8 17.9999 14.5L10.9999 2.5C10.7999 2.2 10.4999 2 10.1999 2C9.89991 2 9.29991 2.2 9.19991 2.5ZM9.99991 11C10.5999 11 10.9999 10.6 10.9999 10V6C10.9999 5.4 10.5999 5 9.99991 5C9.39991 5 8.99991 5.4 8.99991 6V10C8.99991 10.6 9.39991 11 9.99991 11ZM9.99991 15C10.5999 15 10.9999 14.6 10.9999 14C10.9999 13.4 10.5999 13 9.99991 13C9.39991 13 8.99991 13.4 8.99991 14C8.99991 14.6 9.39991 15 9.99991 15Z"
        fill="#FDB81E"
      />
    </svg>
  );
};

export default IconWarning;
