export function GET_NEXT_CONSULT(email: string, token: string) {
  return {
    url: process.env.REACT_APP_ONBOARDING_URL + `api/agendamento?email=${email}`,
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'X-ILY-API-appKey': process.env.REACT_APP_HEADER_APPKEY as string,
        'X-ILY-API-appToken': `Bearer ${token}`
      },
    },
  };
}
