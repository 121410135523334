import React, { ReactNode, useCallback, useContext } from "react";
import { GET_NEXT_CONSULT } from "api/Home/NextConsult";
import { GET_NOTICES_TELEHEALTH, GET_NOTICES_AIRMED } from "api/Home/Notices";
import { useUserContext } from "./UserContext";
interface ContextValue {
  email: any;
  token: any;
  nextConsult: any;
  hasNextConsult: boolean;
  noticeTelehealth: any;
  noticeAirmed: any;
  getAPIContent: () => void;
  hasAvailableConsults: (email: any, token: any) => void;
  hasTelehealthNotices: (token: any) => void;
  hasAirmedNotices: (token: any) => void;
}

interface Props {
  children: ReactNode;
}

export const HomeContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
);

export const HomeContextProvider = ({ children }: Props) => {
  const { login } = useUserContext();
  const token = localStorage.getItem("lifeplace@token");
  const email = localStorage.getItem("lifeplace@email");
  const [nextConsult, setNextConsult] = React.useState(null);
  const [noticeTelehealth, setNoticeTelehealth] = React.useState(null);
  const [noticeAirmed, setNoticeAirmed] = React.useState(null);
  const [hasNextConsult, setHasNextConsult] = React.useState(false);

  const resetNotices = () => {
    setNoticeAirmed(null);
    setNoticeTelehealth(null);
  };

  React.useEffect(() => {
    if (login === false) {
      resetNotices();
    }
  }, [login]);

  const parseReadableStreamToJson = async (error: any) => {
    const data = (await error.getReader().read()).value;
    const str = String.fromCharCode.apply(String, data);
    return JSON.parse(str);
  };

  const hasAvailableConsults = useCallback(async (email: any, token: any) => {

    try {
      const { url, options } = GET_NEXT_CONSULT(email, token);
      const response = await fetch(url, options);
      const consults = await response.json();

      if (response.ok) {
        
        if (
          consults.length > 0 &&
          consults[consults.length - 1].type === "Eletiva"
          ) {
          setHasNextConsult(true);
          setNextConsult(consults[consults.length - 1]);
        } else {
          setNextConsult(null);
          setHasNextConsult(false);
        }
      } else {
        setNextConsult(null);
        setHasNextConsult(false);
        const body = await response.json();
        throw new Error(body.message);
      }
    } catch (error) {
      return;
    }
  }, []);

  const hasTelehealthNotices = useCallback(
    async (token: any) => {
      if (noticeTelehealth !== null) {
        return;
      } else {
        try {
          const { url, options } = GET_NOTICES_TELEHEALTH(token);
          const response = await fetch(url, options);

          if (response.ok) {
            response.body &&
              response.body.locked === false &&
              setNoticeTelehealth(null);
            const notices = await parseReadableStreamToJson(response.body);
            setNoticeTelehealth(notices);
          }
        } catch (error) {
          return;
        }
      }
    },
    [noticeTelehealth]
  );

  const hasAirmedNotices = useCallback(
    async (token: any) => {
      if (noticeAirmed !== null) {
        return;
      } else {
        try {
          const { url, options } = GET_NOTICES_AIRMED(token);
          const response = await fetch(url, options);

          if (response.ok) {
            response.body &&
              response.body.locked === false &&
              setNoticeAirmed(null);
            const notices = await parseReadableStreamToJson(response.body);
            setNoticeAirmed(notices);
          } else {
            const body = await response.json();
            throw new Error(body.message);
          }
        } catch (error) {
          return;
        }
      }
    },
    [noticeAirmed]
  );

  const getAPIContent = useCallback(() => {
    hasAvailableConsults(localStorage.getItem("lifeplace@email"), localStorage.getItem("lifeplace@token"));
    hasTelehealthNotices(localStorage.getItem("telehealth@token"));
    hasAirmedNotices(localStorage.getItem("telehealth@token"));
  }, [hasAirmedNotices, hasTelehealthNotices, hasAvailableConsults]);

  const value = {
    email,
    token,
    hasAvailableConsults,
    nextConsult,
    hasNextConsult,
    hasTelehealthNotices,
    noticeTelehealth,
    hasAirmedNotices,
    noticeAirmed,
    setNextConsult,
    getAPIContent,
  };

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

export function useHomeContext() {
  const context = useContext(HomeContext);

  if (typeof context === "undefined") {
    throw new Error("useHomeContext must be used within an HomeContext");
  }

  return context;
}
