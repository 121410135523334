import Photo from '../../../../assets/bg.svg'
import closeIcon from '../../../../assets/closeSidebar.svg'
import iconArrow from '../../../../assets/ArrowNext.svg'
import { ProfileContent, PhotoContent } from './styles';
import { SidebarData } from '../Navbar/SidebarData';
import IconSair from '../Navbar/icons/IconSair'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';


const Profile = () => {
  const [ sidebar, setSidebar ] = useState(false);
  const {userLogout} = useUserContext()

  const showSidebar = () => {
    setSidebar(!sidebar)
  }

  return (
    <ProfileContent>
      
      <PhotoContent onClick={showSidebar}>
        <img src={Photo} alt="Profile user" />
      </PhotoContent>

      <nav className={sidebar ? 'nav-menu active': 'nav-menu'}>
        <ul className='nav-menu-item'>
          <li className='btn-close' onClick={showSidebar}>
            <img src={closeIcon} alt="" />
            Fechar
          </li>

          <li className="user">
            <img src={Photo} alt="Profile user" />
            <div>
              <strong>Filipe Rosmann</strong>
              <span>filipe@gmail.com</span>
            </div>
          </li>

          {SidebarData.map((item, index)=>{
          return (
            <Link to={item.path}>
              <li key={index} className={item.cName}>
                <div>
                  {item.icon}
                  <span>{item.title}</span>
                </div>
                <img src={iconArrow} alt="Icon Arrow" />
              </li>
            </Link>
          )
          })}

          <Link to='/' onClick={userLogout}>
            <li className='nav-item'>
              <div>
                <IconSair />
                <span>Sair</span>
              </div>
              <img src={iconArrow} alt="Icon Arrow" />
            </li>
          </Link>
        </ul>
      </nav>
    </ProfileContent>
  )
}

export default Profile;