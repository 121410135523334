import React from "react";
import { TextField } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

type TextProps = {
  value?: string;
  label: string;
  placeholder?: string;
  errorText?: string | null;
  setError?: boolean;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => void
} 

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "0.9rem",
      fontWeight: "normal",
      
      "& label": {
        color: "var(--life-neutral-black-64)",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "0.9rem",
        fontWeight: "400",
        
      },
      "& label.Mui-focused": {
        color: "var(--life-neutral-black-88)",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#fff",
        "& fieldset": {
          border: "2px solid var(--life-neutral-black-16)",
        },
        "&:hover fieldset, &.Mui-focused fieldset": {
          borderColor: "var(--primary)",
        },
      },
      "& .MuiOutlinedInput-input": {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "0.9rem",
        fontWeight: "normal",
        verticalAlign: 'middle',
      },
      "& label.Mui-error": {
        color: "var(--life-neutral-black-88)",

      },
      "& .Mui-error.MuiOutlinedInput-root": {
        "& fieldset": {
          border: "2px solid var(--life-alert-error)",

        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: "var(--life-alert-error)",
        fontSize: "0.75rem",
        lineHeight: "1rem",
        fontWeight: "400",
        fontFamily: "Montserrat, sans-serif",
        margin: "11px 0 11px 4px",
      },
    },
  })
);

const Input = ({
  value,
  label,
  errorText,
  setError,
  placeholder,
  onChange,
  handleBlur,
  id,
  ...rest
}: TextProps) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.root}
      value={value}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      variant="outlined"
      helperText={errorText && errorText}
      error={errorText ? true : false}
      onBlur={handleBlur}
      inputProps={{ "data-testid": id }}
      {...rest}
    />
  );
};

export default Input;
