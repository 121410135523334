import React from 'react'
import './Button.css'

type ButtonProps = {
   style?: React.CSSProperties
   variant: "outlined"
   children: React.ReactNode
} & React.ComponentProps<'button'>
 

const ButtonOutlined = ({variant ,children, style, ...rest}: ButtonProps) => {
    return (
      <button style={style} {...rest} className={`button ${variant}`}>
        {children}
      </button>
    )
}


export default ButtonOutlined
