

const IconTermos = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 9C18 4 14 0 9 0C4 0 0 4 0 9C0 14 4 18 9 18C14 18 18 14 18 9ZM2 9C2 5.1 5.1 2 9 2C12.9 2 16 5.1 16 9C16 12.9 12.9 16 9 16C5.1 16 2 12.9 2 9ZM9 8C9.6 8 10 8.4 10 9V12C10 12.6 9.6 13 9 13C8.4 13 8 12.6 8 12V9C8 8.4 8.4 8 9 8ZM8 6C8 6.6 8.4 7 9 7C9.6 7 10 6.6 10 6C10 5.4 9.6 5 9 5C8.4 5 8 5.4 8 6Z" fill="#2B45D4"/>
    </svg>
  )
}

export default IconTermos;