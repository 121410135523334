import { InputHTMLAttributes } from 'react'
import { CheckboxLife } from "./styles"

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement>{
  label: string
  value?: string;
  children: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement >) => void
}

const Checkbox = ({label, value, children, onChange}:CheckboxProps) => {
  return (
    <CheckboxLife>
      <input id={label} value={value}  type="checkbox" onChange={onChange} />
      <label htmlFor={label}>
        <p>{children}</p>
      </label>
    </CheckboxLife>
  )
}

export default Checkbox
