import styled from 'styled-components'


export const Container = styled.div`
  height: 425px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-right: 165px;

  button {
    margin-top: 2rem;
  } 

  @media (max-width:1160px) {
    margin-right: 0;
  }
`

export const Titulo = styled.h1`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: var(--primary);
  font-family: 'Montserrat', sans-serif;
  font-size: normal;
`

export const Texto = styled.p`
  font-weight: 600;
  font-size: 1rem;
  color: var(--life-neutral-black-88);
  line-height: 1.5rem;
  margin-bottom: 8px;
  margin-top: 2rem;
`

export const TextoInfo = styled.p`
  font-size: 0.875rem;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
  margin-top: 1rem;
  margin-bottom: 2rem;
`