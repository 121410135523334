import styled from "styled-components";

export const ContentProgress = styled.div`
  width: 4.5rem;
  height: 4.5rem;

  .CircularProgressbar-path {
    stroke: #FF0073;
  }

  .CircularProgressbar-trail {
    stroke: #ECECEC;
  }

`