import { EmailProps } from 'api/types/userAuth'
import { headerAppToken } from 'api/headers/HeaderAppToken'

export type UserAndTermsProps = {
    aceiteComunicacao: boolean, 
    aceiteTermosUso: boolean
    email: string, 
    nomeCompleto: string,
}

export function POST_ONBOARDING(body: UserAndTermsProps, token: string) {
    return {
      url: process.env.REACT_APP_ONBOARDING_URL + 'api/onboarding',
      options: {
        method: 'POST',
        headers: headerAppToken(token),
        body: JSON.stringify(body)
      }
    }
  }


export function GET_ME(body:EmailProps, token: string){
  return {
    url: process.env.REACT_APP_ONBOARDING_URL + `api/onboarding?email=${body.email}`,
    options: {
      method: 'GET',
      headers: headerAppToken(token),
    }//fim do options
  }
}


