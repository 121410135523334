const IconInfo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12C21 7 17 3 12 3C7 3 3 7 3 12C3 17 7 21 12 21C17 21 21 17 21 12ZM5 12C5 8.1 8.1 5 12 5C15.9 5 19 8.1 19 12C19 15.9 15.9 19 12 19C8.1 19 5 15.9 5 12ZM12 11C12.6 11 13 11.4 13 12V15C13 15.6 12.6 16 12 16C11.4 16 11 15.6 11 15V12C11 11.4 11.4 11 12 11ZM11 9C11 9.6 11.4 10 12 10C12.6 10 13 9.6 13 9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9Z"
        fill="white"
      />
    </svg>
  );
};

export default IconInfo;
