import Button from '../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import AppleSignin from 'react-apple-signin-auth'
import GoogleLogin from 'react-google-login'
import { useUserContext } from 'contexts/UserContext'


const FormRight = () => {

  const navigate = useNavigate()
  const { conectarGoogle, conectarApple} = useUserContext()

  return (
    <div className="information">
      <h1>O cuidado com a sua saúde está só começando!</h1>
      <p>Como você quer entrar?</p>

      <AppleSignin
        authOptions={{
          clientId: 'health.maida.lifeplace.service',
          redirectURI: 'https://lifeplace.maida.health',
          scope: 'email name',
          state: 'state',
          nonce: 'nonce',
          usePopup: true,  
        }}
          uiType="light"
          className="btn-apple"
          buttonExtraChildren="Entrar com Apple"
          // onSuccess={(response) => console.log(response)}
          onSuccess={conectarApple}
          onError={(error) => console.error(error)}
          iconprop={{ style: { marginTop: '10px' } }}
      />

      <GoogleLogin
        clientId="523875628162-l8dql5ctihq3ck35cr3c045qk1kt2h25.apps.googleusercontent.com" /// Maida
        // clientId="25788838536-696uoofab4bt783fjbh1ensgqombe458.apps.googleusercontent.com"
        render={renderProps => (
          <button 
            onClick={renderProps.onClick} 
            disabled={renderProps.disabled} 
            className="btn-login">
            <span>Entrar com Google</span>
          </button>
        )}
        onSuccess={conectarGoogle} 
      /> 

      <Button variant="primary" onClick={() => navigate('email')}>Entrar com e-mail</Button>
    </div>
  )
}

export default FormRight
