import { BoxLeft, BoxRight, Container, Content, ContentBox } from "./styles";
import imageError from "../../assets/Backgrounds/Error.svg";
import ContainerError from "../Error/ContainerError";

type OrderProps = {
  ordem: boolean;
  isComponent?: boolean;
};

const Error = ({ ordem, isComponent }: OrderProps) => {
  return (
    <Container className={isComponent ? "component" : ""}>
      <Content>
        <ContentBox>
          <BoxLeft ordem={ordem}>
            <img src={imageError} alt="Página de erro 404" />
          </BoxLeft>

          <BoxRight ordem={ordem}>
            <ContainerError />
          </BoxRight>
        </ContentBox>
      </Content>
    </Container>
  );
};

export default Error;
