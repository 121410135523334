
import Profile from './Profile';
import { Heading } from './styles';
import TextGreetings from './TextGreetings/index';

const Header = () => {
  

  return (
    <Heading>
      <TextGreetings />
      <Profile />
    </Heading>
  );
};

export default Header;
