import {EmailProps, CodeProps} from '../types/userAuth'
import { HeaderAuth } from 'api/headers/HeaderAuth'
import { headerAppToken } from 'api/headers/HeaderAppToken'

export function POST_AUTH_EMAIL(body:EmailProps){
  return {
    url: process.env.REACT_APP_API_URL + "auth/email",
    options: {
      method: "POST",
      headers: HeaderAuth,
      body: JSON.stringify(body)
    }//fim do options
  }
}

export function GET_CHECK_EMAIL(body:EmailProps, token: string){
  return {
    url: process.env.REACT_APP_ONBOARDING_URL + `api/onboarding?email=${body.email}`,
    options: {
      method: 'GET',
      headers: headerAppToken(token),
    }//fim do options
  }
}

export function POST_CHECK_CODE(body: CodeProps){
  return {
    url: process.env.REACT_APP_API_URL + 'auth/checkCode', 
    options: {
      method: 'POST',
      headers: HeaderAuth,
      body: JSON.stringify(body)
    }//fim do options
  }
}

export function POST_AUTH_APPLE(body: any) {
  return {
    url: process.env.REACT_APP_API_URL + "auth/apple",
    options: {
      method: 'POST',
      headers: HeaderAuth,
      body: JSON.stringify(body),
    },
   
  };
}

export function POST_AUTH_GOOGLE(body: any) {
  return {
    url: process.env.REACT_APP_API_URL + "auth/google",
    options: {
      method: 'POST',
      headers:HeaderAuth,
      body: JSON.stringify(body),
    },
  };
}
