import styled from "styled-components";

// header greetings
export const Heading = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;

  @media (max-width: 1280px) {
    padding: 0 24px;
  }

  @media (max-width: 768px) {
    margin: 25px 0 30px 0;
    align-items: flex-start;
  }
`;