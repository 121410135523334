import { BoxLeft, BoxRight, Container, Content, ContentBox } from "./styles";
import onboarding from '../../assets/onboarding.svg'
import FormLeftNome from "../Login/FormLeftNome";

type OrderProps = {
  ordem: boolean,
}

const Onboarding = ({ordem }:OrderProps) => {
  return (
    <Container>

      <Content>
        {/* <ContentHeader>
          <ButtonBack variant="back">Voltar</ButtonBack>
        </ContentHeader> */}

        <ContentBox>
          <BoxLeft ordem={ordem}>
            <img src={onboarding} alt="Imagem de onboarding do Lifeplace" />
          </BoxLeft>

          <BoxRight ordem={ordem}>
            <FormLeftNome/>
          </BoxRight>
        </ContentBox>
      </Content>
    </Container>
  )
}

export default Onboarding;
