import { useUserContext } from "contexts/UserContext";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRouteAuth = () => {
  const { userData } = useUserContext();

  const auth = userData.email; 
  
  return auth ? <Outlet /> : <Navigate to="email" />;
};

export default PrivateRouteAuth;
