import React, { ReactNode, useState } from "react";
import {GET_CHECK_EMAIL,POST_AUTH_APPLE,POST_AUTH_EMAIL,POST_AUTH_GOOGLE,POST_CHECK_CODE} from "api/User/UserAuth";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { UserProps, ProfileObjsProps, TokenObjProps, AppleLogin, ProfileApplePros } from "./types/UserTypes";
import { CodeProps } from "api/types/userAuth";
import {GET_ME,POST_ONBOARDING,UserAndTermsProps} from "api/User/UserOnboarding";


//#######################################################
// CRIACAO DO CONTEXTO E SUAS PROPRIEDADES
//#######################################################
type ContextProps = {
  userData: UserProps;
  login: boolean;
  error: boolean | string | null;
  loading: boolean;
  conectarGoogle:         (response: any)   => void;
  conectarApple:          (response: any)   => void;
  conectarEmail:          (email: string)   => void;
  authWithEmail:          (email: string)   => void;
  validationCODE:         (code: CodeProps) => void;
  confirmationOnboarding: (infoETermos: UserAndTermsProps) => void;
  userLogout: () => void;
};

type Props = {
  children: ReactNode;
};

//criacao de um contexto tipado para que seja possivel armazenar os dados a serem compartilhados
const UserContext = React.createContext<ContextProps | undefined>(
  {} as ContextProps
);

// Criando o Componente Para que seja possível compartilhar as informações com os componentes filhos ..
export const UserProvider = ({ children }: Props) => {

  const navigate = useNavigate();
  //cria o estado para as informacoes de um usuario que fez login com Google
  const [userData, setUserData] = useState<UserProps>({} as UserProps);
  const [login, setLogin] = useState(false);

  const [error, setError] = React.useState<boolean | string | null>(null);
  const [loading, setLoading] = React.useState(false);




  //#######################################################
  // METODO DE LOGOUT 
  //#######################################################

  const userLogout = React.useCallback(
    async function () {
      setError(false);
      setLoading(false);
      setLogin(false);
      window.localStorage.removeItem("lifeplace@token");
      window.localStorage.removeItem("telehealth@token");
      window.localStorage.removeItem("lifeplace@email");

      navigate("/");
    },
    [navigate]
  );


  //#######################################################
  // MÉTODO QUE OBTEM INFORMACOES BASICAS DO USUARIO
  //#######################################################
  async function getUser(email: string) {
    
    const token = localStorage.getItem('lifeplace@token') as string

    const { url, options } = GET_ME({ email: email }, token);

    try {
      setLoading(true);
      setError(false);
      const response = await fetch(url, options);

      const json = await response.json();
      const { nomeCompleto, email, teleHealth} = json;
      let nome = nomeCompleto.split(" ");
      setUserData({ email: email, firstName: nome[0], lastName: nome[1] });

      window.localStorage.setItem("telehealth@token", teleHealth);

      setLogin(true);
      navigate("/home");
    } catch (error) {
      setError(true);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  //#######################################################
  // AUTO LOGIN
  //#######################################################
  async function autoLogin() {
    const token = window.localStorage.getItem("lifeplace@token");
    const email = window.localStorage.getItem("lifeplace@email");

    if (token) {
      try {
        if (email) {
          await getUser(email.replace("@","%40"));
        }
        setError(false);
        setLoading(true);
      } catch (err) {
        userLogout();
      } finally {
        setLoading(false);
      }
    }
  }

  //#######################################################
  // MÉTODOS DE SALVAR NO ESTADO O EMAIL DEPOIS DA TELA INICIAL
  //####################################################### 

  const conectarEmail = (email: string) => {
    if (email) {
      setUserData({ ...userData, email: email });
    }
  };


  //#######################################################
  // MÉTODOS DE LOGIN
  //####################################################### 
  async function authWithEmail(email: string) {
    try {
      setError(false);
      setLoading(true);

      // Envia o código para o seu email
      const { url, options } = POST_AUTH_EMAIL({ email: email });
      const response = await fetch(url, options);
      // console.log(response);

      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.message);
      }

      conectarEmail(email); // salva o email no setData
      window.localStorage.setItem("lifeplace@email", email);

      navigate("/code");
    } catch (error) {
      setError(String(error));
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }

  async function checkEmail(email: string) {
    try {
      setError(false);
      setLoading(true);

      const token = localStorage.getItem('lifeplace@token') as string

      let replaceEmail = email.replace("@", "%40");

      const { url, options } = GET_CHECK_EMAIL({ email: replaceEmail }, token);
      const response = await fetch(url, options);

      //Se deu algum erro, o usuario é NOVO - então vai para /onboarding
      if (!response.ok) {
        navigate("/onboarding");
        return;
      }

      //usuario existente vai para home
      window.localStorage.setItem("lifeplace@email", email);

      await getUser(replaceEmail);
      
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }


  //#######################################################
  // LOGIN SOCIAIS
  //####################################################### 
  const conectarGoogle = async (response: any) => {
    const {
      profileObj: { name, email },
      tokenId,
    } = response;

    setUserData({ ...userData, firstName: String(name), email: String(email) });

    const Profile: ProfileObjsProps = {
      name,
    };

    const ProfileToken: TokenObjProps = {
      id_token: tokenId,
    };

    const body = { profileObj: Profile, tokenObj: ProfileToken };

    await authWithGoogle(body, name);
  };

  async function authWithGoogle(body: any, name: string) {
    try {
      setError(false);
      setLoading(true);
      const { url, options } = POST_AUTH_GOOGLE(body);
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error("erro");
      }

      const json = await response.json();
      const { jwttoken, userData } = json;
      const { email } = userData;

      //##############################################
      // Segundo endpoint que retorna o TOKEN
      //##############################################
      window.localStorage.setItem("lifeplace@token", jwttoken);

      await checkEmail(email); //vai pra home ou onboarding

      // Nao se sei se esse bloco de código abaixo é necessário 
      // pois nao sei se ele chega a ser exucutado, mas acredito que sim
      const nome = name.split(" ");
      setUserData({ email: email, firstName: nome[0], lastName: nome[1] });

      window.localStorage.setItem("lifeplace@email", email);
      window.localStorage.setItem("lifeplace@token", jwttoken);
      //fim do bloco =============================================

    } catch (error) {
      setError(String(error));
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }
  const conectarApple = async (response: any) => {
    const { authorization } = response;
    const { id_token } = authorization;
    const { code } = authorization;


    const myDecodedToken = decodeToken(id_token);
    
    //fazendo casting implícito que o dev garante que ele será do formato AppleLogin
    const email = (myDecodedToken as AppleLogin).email;

    const firstName = "firstName";
    const lastName = "lastName";

    const Profile: ProfileApplePros = {
      code,
      email,
      firstName,
      lastName,
    };
    await authWithApple(Profile);
  };
  async function authWithApple(body: any) {
    // console.log(body)

    try {
      setError(false);
      setLoading(true);
      const { url, options } = POST_AUTH_APPLE(body);
      const response = await fetch(url, options);

      if (!response.ok) {
        // const body = await response.json()
        throw new Error("Erro na API");
      }

      const json = await response.json();
      const { jwttoken, userData } = json;
      const { firstName, lastName } = userData;

      window.localStorage.setItem("lifeplace@email", body.email);

      setUserData({
        email: body.email,
        firstName: firstName,
        lastName: lastName,
      });
    
      await checkEmail(body.email); //vai pra home ou onboarding

      
      //###############################################
      // Terceiro lugar que obtem token
      //###############################################

      window.localStorage.setItem("lifeplace@token", jwttoken);


    } catch (error) {
      setError(String(error));
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }

  // ######################################################
  //  MÉTODO DE VERIFICAÇÃO DE CÓDIGO DE 4 DÍGITOS
  // ######################################################

  async function validationCODE(code: CodeProps) {
    try {
      setError(false);
      setLoading(true);

      const { url, options } = POST_CHECK_CODE(code);
      const response = await fetch(url, options);

      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.message);
      }

      const json = await response.json();
      const { jwttoken, userData } = json;
      const { firstName, lastName } = userData;

      //Add o email no local storage
      window.localStorage.setItem("lifeplace@email", code.email);

      setUserData({
        email: code.email,
        firstName: firstName,
        lastName: lastName,
      });

      //##############################################
      // Primeiro endpoint que retorna o TOKEN
      //##############################################
      window.localStorage.setItem("lifeplace@token", jwttoken);

      // Checa se o usuário é novo ou não
      await checkEmail(code.email);


    } catch (error) {
      setError(String(error));
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }

  // ######################################################
  //  MÉTODO DE CONFIRMAÇÃO DO ONBOARDING
  // ######################################################

  async function confirmationOnboarding(infoETermos: UserAndTermsProps) {
    try {
      setError(false);
      setLoading(true);

      const token = localStorage.getItem('lifeplace@token') as string

      const { url, options } = POST_ONBOARDING(infoETermos,token);

      const response = await fetch(url, options);

      if (!response.ok) {
        const body = await response.json();
        throw new Error(body.message);
      }

      await getUser(infoETermos.email.replace("@", "%40"));

    } catch (error) {
      setError(String(error));
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    autoLogin();
  }, []);

  //pode passar varias coisas aqui, de inicio so eh passado o "userData"
  const value = {
    userData,
    conectarGoogle,
    conectarApple,
    conectarEmail,
    login,
    userLogout,
    error,
    loading,
    authWithEmail,
    authWithGoogle,
    validationCODE,
    confirmationOnboarding,
  };

  //retorna o provedor de dados realmente
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useUserContext() {
  const context = React.useContext(UserContext);

  if (typeof context === "undefined") {
    throw new Error("useUserContext must be used within an UserContext");
  }

  return context;
}
