import styled from 'styled-components'


export const ContentCardExame = styled.div`
  display: flex;
  width: 60%;
  overflow-x: hidden;
  position: absolute;
  
  .swiper-container {
      width: 330px;
      
      padding: 0 40px 0 0;

    .swiper-wrapper {
      /* background: blue; */
      display: flex;
    }
    .swiper-slide {
      width: 100%;
    /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  } 

  @media (max-width: 970px) {
    width: 93%;   
  }
`

export const CardItem = styled.div`
  width: 226px;
  height: 227px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-right: 1rem;

  > a {
    text-decoration: none;
    color: #FF0073;
    font-size: 1rem;
    font-weight: 600;
  }
`

export const IconContent = styled.div`
  height: 51px;
  margin-bottom: 8px;
`

export const Title = styled.p`
  font-size: 1rem;
  color: var(--primary);
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 8px;
`

export const Text = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.56);
  line-height: 24px;
  margin-bottom: 20px;
`

