import styled from "styled-components";
import IconClose from "./IconClose";
import IconDanger from "./IconDanger";
import IconInfo from "./IconInfo";
import IconSuccess from "./IconSuccess";
import IconWarning from "./IconWarning";

interface NotificationProps {
  status: "success" | "error" | "warning" | "important";
  message: string;
  canClose: boolean;
}

const Index = ({ status, message, canClose }: NotificationProps) => {
  const icons = {
    success: {
      icon: <IconSuccess />,
    },
    error: {
      icon: <IconDanger />,
    },
    warning: {
      icon: <IconWarning />,
    },
    important: {
      icon: <IconInfo />,
    },
  };

  return (
    <Box className={`box-${status}`}>
      <div className={`icon-${status}`}>
        {status === "success" && icons.success.icon}
        {status === "error" && icons.error.icon}
        {status === "warning" && icons.warning.icon}
        {status === "important" && icons.important.icon}
      </div>
      <Message>{message}</Message>
      {canClose && (
        <Close className={`close-${status}`}>
          <IconClose />
        </Close>
      )}
    </Box>
  );
};

// exemplo: <AlertNotification
//   status="error"
//   message="Código incorreto."
//   canClose={false}
// />

export default Index;

export const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  box-sizing: border-box;
  color: #daabab;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #fff;

  & > div {
    display: flex;
    align-items: center;
  }

  &.box-success {
    background-color: var(--life-alert-success);
  }
  &.box-success .close-success > svg > path {
    fill: var(--primary);
  }

  &.box-error {
    background-color: var(--life-alert-error);
  }
  &.box-error .close-error > svg > path {
    fill: #fff;
  }

  &.box-warning {
    background-color: #fff;
    color: var(--ily-neutral-black-64);
  }
  &.box-warning .icon-warning > svg > path {
    fill: var(--ily-alert-warning);
  }
  &.box-warning .close-warning > svg > path {
    fill: var(--primary);
  }

  &.box-important {
    background-color: var(--ily-secondary);
    color: var(--ily-neutral-black-64);
  }
  &.box-important .icon-important > svg > path {
    fill: var(--ily-neutral-black-64);
  }
  &.box-important .close-important > svg > path {
    fill: var(--primary);
  }
`;

export const Message = styled.div`
  padding-left: 16px;
`;

export const Close = styled.div`
  cursor: pointer;
`;
