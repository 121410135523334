
import CircleProgressbar from 'pages/Home/CircleProgressbar';
import { Card } from './styles';

const LevelCard = () => {
  return (
    <Card>
      <CircleProgressbar value={78} />
      
      <div className='information'>
        <strong>Nível 1</strong>
        <p>Chegue ao <span>nível 2</span> acumulando 100 pontos</p>
      </div>
    </Card>
  )
}

export default LevelCard; 