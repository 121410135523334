import React from 'react'
import Button from '../../../components/Button/Button'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const FormRightError = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Titulo>Algo errado não está certo...</Titulo>
      <TextoInfo>Não foi possível completar sua solicitação. Mas que tal tentarmos de novo?</TextoInfo>

      <Button variant="primary" onClick={() => navigate('/home')}  style={{marginTop:'24px'}}>Voltar para o início</Button>
    </Container>
  )
}

const Container = styled.div`
  height: 351px;
  max-width: 328px;
  display: flex;
  flex-direction: column;
 
`

const Titulo = styled.h1`
  font-size: 24px;
  color: var(--primary);
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: normal;
`

const TextoInfo = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.64);
  margin-top: 1rem;
  margin-bottom: 32px;
`

export default FormRightError;

