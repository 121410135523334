import { useUserContext } from "contexts/UserContext";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const { login} = useUserContext();
 
  const auth = login; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/" />;
  // return auth ? <Outlet /> : <Outlet />;
};

export default PrivateRoute;
