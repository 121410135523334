
const Prescricao = () => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9998 12C14.3998 12 13.9998 11.6 13.9998 11V2.89999C13.9998 2.39999 13.6997 2 13.2997 2H2.69977C2.29977 2 1.99976 2.39999 1.99976 2.89999V17.1C1.99976 17.6 2.29977 18 2.69977 18H6.99976C7.59976 18 7.99976 18.4 7.99976 19C7.99976 19.6 7.59976 20 6.99976 20H2.69977C1.19977 20 -0.000244141 18.7 -0.000244141 17.1V2.89999C-0.000244141 1.29999 1.19977 0 2.69977 0H13.2997C14.7997 0 15.9998 1.29999 15.9998 2.89999V11C15.9998 11.6 15.5998 12 14.9998 12Z" fill="#2B45D4"/>
      <path d="M2.99997 5.00011C2.99997 4.44783 3.44768 4.00011 3.99997 4.00011H12C12.5523 4.00011 13 4.44783 13 5.00011C13 5.5524 12.5523 6.00011 12 6.00011H3.99997C3.44768 6.00011 2.99997 5.5524 2.99997 5.00011Z" fill="#2B45D4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.41411 10.4144C5.24254 11.586 5.24253 13.4855 6.41411 14.6571L10.6567 18.8997C11.8283 20.0713 13.7278 20.0713 14.8994 18.8997C16.071 17.7281 16.071 15.8287 14.8994 14.6571L10.6567 10.4144C9.48518 9.24286 7.58568 9.24286 6.41411 10.4144ZM10.6568 13.2429L9.24253 11.8287C8.85201 11.4381 8.21885 11.4381 7.82832 11.8287C7.4378 12.2192 7.4378 12.8523 7.82832 13.2429L9.24257 14.6571L10.6568 13.2429ZM10.6568 16.0713L12.071 17.4855C12.4615 17.876 13.0947 17.876 13.4852 17.4855C13.8757 17.095 13.8757 16.4618 13.4852 16.0713L12.071 14.6571L10.6568 16.0713Z" fill="#2B45D4"/>
    </svg>
  )
}

export default Prescricao;