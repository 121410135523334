import styled from 'styled-components'

export const TextContent = styled.section`
  display: flex;
  flex-direction: column;
`

export const TitleGreeting = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px;

  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 40px;
    color: var(--primary);
  }
`;

export const WrapperIcon = styled.div`
  display: block;
  margin-left: 20px;
`;

export const SubtitleGreeting = styled.div`
  display: block;
  & p {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--life-neutral-black-88);
  }
`;
