import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ButtonBack.css";

type ButtonProps = {
  style?: React.CSSProperties;
  variant: "back";
  icon?: React.ReactComponentElement<"svg">;
  allRoutes?: any;
  routesID?: any;
} & React.ComponentProps<"button">;

const ButtonBack = ({
  style,
  variant,
  children,
  icon,
  allRoutes,
  routesID,
  ...rest
}: ButtonProps) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const handleRoute = () => {
    const numberActivePath = routesID[pathname]; //0 ou 1 ou 2 ou 3
    //a rota atual te dará um numero
    //que esta dentro do objeto

    if (numberActivePath > 0) {
      if (pathname === "/agendar-consulta/urgente") {
        navigate(allRoutes[numberActivePath - 2]);
      }else {
        //para caso ele ta na rota 0 .. nao ficar subtraindo
        navigate(allRoutes[numberActivePath - 1]);
      }
    }
  };

  return (
    <button
      style={style}
      {...rest}
      onClick={handleRoute}
      className={`button-back ${variant}`}
    >
      {icon && <span>{icon}</span>}
      {children}
    </button>
  );
};

export default ButtonBack;
