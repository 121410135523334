import { GlobalStyle } from "./styles/global";
import { DrawerContextProvider } from "../src/contexts/DrawerContext";
import { HomeContextProvider } from "contexts/HomeContext";
import UserAuth from "routes/UserAuth";

function App() {
  return (
    <>
      <HomeContextProvider>
        <DrawerContextProvider>
          <GlobalStyle />
          <UserAuth />
        </DrawerContextProvider>
      </HomeContextProvider>
    </>
  );
}

export default App;
