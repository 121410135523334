const IconClose = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4998 7L12.8998 11.4C13.2998 11.8 13.2998 12.4 12.8998 12.8L12.7998 12.9C12.3998 13.3 11.7998 13.3 11.3998 12.9L6.9998 8.5L2.5998 12.9C2.1998 13.3 1.59981 13.3 1.19981 12.9L1.0998 12.8C0.699805 12.4 0.699805 11.8 1.0998 11.4L5.4998 7L1.0998 2.6C0.699805 2.2 0.699805 1.60001 1.0998 1.20001L1.19981 1.1C1.59981 0.700003 2.1998 0.700003 2.5998 1.1L6.9998 5.5L11.3998 1.1C11.7998 0.700003 12.3998 0.700003 12.7998 1.1L12.8998 1.20001C13.2998 1.60001 13.2998 2.2 12.8998 2.6L8.4998 7Z"
        fill="white"
      />
    </svg>
  );
};

export default IconClose;
