

const IconPedidos = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 7H13C13.2652 7 13.5196 6.89464 13.7071 6.70711C13.8946 6.51957 14 6.26522 14 6C14 5.73478 13.8946 5.48043 13.7071 5.29289C13.5196 5.10536 13.2652 5 13 5H5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.10536 5.48043 4 5.73478 4 6C4 6.26522 4.10536 6.51957 4.29289 6.70711C4.48043 6.89464 4.73478 7 5 7ZM5 11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9H5C4.73478 9 4.48043 9.10536 4.29289 9.29289C4.10536 9.48043 4 9.73478 4 10C4 10.2652 4.10536 10.5196 4.29289 10.7071C4.48043 10.8946 4.73478 11 5 11ZM17 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V19C0 19.1857 0.0517147 19.3678 0.149349 19.5257C0.246984 19.6837 0.386681 19.8114 0.552786 19.8944C0.718892 19.9775 0.904844 20.0126 1.08981 19.996C1.27477 19.9793 1.45143 19.9114 1.6 19.8L3.67 18.25L5.73 19.8C5.9031 19.9298 6.11363 20 6.33 20C6.54637 20 6.7569 19.9298 6.93 19.8L9 18.25L11.07 19.8C11.2431 19.9298 11.4536 20 11.67 20C11.8864 20 12.0969 19.9298 12.27 19.8L14.33 18.25L16.4 19.8C16.5493 19.9113 16.7267 19.9786 16.9122 19.9945C17.0977 20.0104 17.284 19.9742 17.45 19.89C17.6149 19.8069 17.7536 19.6798 17.8507 19.5227C17.9478 19.3656 17.9994 19.1847 18 19V10V1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0ZM16 17L14.93 16.2C14.7569 16.0702 14.5464 16 14.33 16C14.1136 16 13.9031 16.0702 13.73 16.2L11.67 17.75L9.6 16.2C9.4269 16.0702 9.21637 16 9 16C8.78363 16 8.5731 16.0702 8.4 16.2L6.33 17.75L4.27 16.2C4.0969 16.0702 3.88637 16 3.67 16C3.45363 16 3.2431 16.0702 3.07 16.2L2 17V9.5V5.75V3C2 2.44772 2.44772 2 3 2H15C15.5523 2 16 2.44772 16 3V9.5V17Z" fill="#2B45D4"/>
    </svg>
  )
}

export default IconPedidos;