import React, { ReactNode, useContext } from "react";
interface ContextValue {
  open: boolean;
  isResponsive: boolean;
  handleDrawerOpen: () => void;
  toggleDrawer: any;
}
interface Props {
  children: ReactNode;
}

export const DrawerContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
);

export const DrawerContextProvider = ({ children }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isResponsive, setIsResponsive] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      setIsResponsive(true);
    }
  }, []);

  window.addEventListener("resize", () => {
    window.innerWidth <= 768 ? setIsResponsive(true) : setIsResponsive(false);
  });

  function handleDrawerOpen() {
    setOpen(!open);
  }

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      handleDrawerOpen();
    };


  const value = { open, isResponsive, handleDrawerOpen, toggleDrawer };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export function useDrawerContext() {
  const context = useContext(DrawerContext);

  if (typeof context === "undefined") {
    throw new Error("useDrawerContext must be used within an DrawerContext");
  }

  return context;
}
