import NenhumAgendamento from '../../../../assets/agendamento.svg'
import { ContentAnyConsutl } from './styles';

const AnyConsult = () => {
  return (
    <ContentAnyConsutl>
      <img src={NenhumAgendamento} alt="Nenhum" />
      <p>Você não tem nenhuma consulta para os próximos dias</p>
    </ContentAnyConsutl>
  )
}

export default AnyConsult;