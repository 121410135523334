import { Container, Content } from "./styles";
import ImageMain from "../../assets/Lifeplace.svg";
import { ReactComponent as IconBack } from "../../assets/arrowback.svg";
import ButtonBack from "../../components/Button/ButtonBack";
import { Outlet, useLocation } from "react-router-dom";

const Login = () => {
  

  const rotas: any = {
    0: "/",
    1: "/email",
    2: "/code",
    3: "/onboarding",
  };

  const destinoRotas: any = {
    "/": 0,
    "/email": 1,
    "/code": 2,
    "/onboarding": 3,
  };


  const {pathname} = useLocation()


  return (
    <Container>
      <Content>
        {pathname !== '/' && <header>
          <ButtonBack
            variant="back"
            allRoutes={rotas}
            routesID={destinoRotas}
            icon={<IconBack />}
          >
            Voltar
          </ButtonBack>
        </header>}
        <div className="main">
          {/* Lado esquerdo */}
          <div className="content-image">
            <img src={ImageMain} alt="Imagem de fundo do Lifeplace" />
          </div>

          <Outlet />

        </div>
      </Content>
    </Container>
  );
};

export default Login;
