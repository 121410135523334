import { Swiper, SwiperSlide } from "swiper/react";
import { ContentCardExame, CardItem, IconContent, Title, Text} from "./styles";


const ExamCard = () => {
  return (
    <ContentCardExame>
      <Swiper slidesPerView={'auto'} spaceBetween={0} pagination={{"clickable": true}} className="mySwiper">
        <SwiperSlide>
          <CardItem>
            <IconContent></IconContent>
            <Title>Consultas</Title>
            <Text>Veja as especialidades disponíveis!</Text>
            <a href="#">Faça sua consulta</a>
          </CardItem>
        </SwiperSlide>

        <SwiperSlide>
          <CardItem>
            <IconContent></IconContent>
            <Title>Loja</Title>
            <Text>Tudo o que você precisa da farmácia</Text>
            <a href="#">Conheça a loja</a>
          </CardItem>
        </SwiperSlide>

        <SwiperSlide>
          <CardItem>
            <IconContent></IconContent>
            <Title>Exames</Title>
            <Text>Tenha seus exames na palma da mão</Text>
            <a href="#">Guarde seus exames</a>
          </CardItem>
        </SwiperSlide>
      </Swiper>
    </ContentCardExame>
  )
}

export default ExamCard;