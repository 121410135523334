import styled from "styled-components";

export const Card = styled.div`
  width: 504px;
  height: 104px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1.5rem;

  .information {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;

    strong {
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 24px;
      color: var(--primary);
    }

    > p {
      color: rgba(0, 0, 0, 0.56);
      font-size: 1rem;
      line-height: 24px;

      span {
        font-weight: bold;
        color: black;
      }
    }
  }

  @media(max-width:870px) {
    width: 100%;
  }

`