import Clock from "../../../assets/clock.svg";
import Consult from "../../../assets/consult.svg";

const Index = () => {
  return (
    <div className="main">
      <div className="header-main">
        <h2>Consultas disponíveis</h2>
        <p>
          Você tem <span>4 consultas</span> para usar
        </p>
      </div>
      <div className="content-box">
        <div className="box">
          <div className="header-consulta">
            <img src={Clock} alt="Clock" />
            <h4>Consulta Online Agendada</h4>
          </div>
          <p>Escolha especialidade, dia e horário da sua consulta</p>
          <a
           
           //href={`https://telemed-ily-web-hmg.dokku.maida.health/auth/${localStorage.getItem(
           href={`${process.env.REACT_APP_FRONT_ANGULAR}auth/${localStorage.getItem(
              "telehealth@token"
            )}?type=ELECTIVE`}
            className="btn-consulta"
          >
            Agendar consulta
          </a>
        </div>
        <div className="box active">
          <div className="header-consulta">
            <img src={Consult} alt="Consult" />
            <h4>Consulta Online de Urgência</h4>
          </div>
          <p>
            Atendimento imediato entre 7h e 19h com profissionais de clínica
            médica
          </p>
          <a
           
          //  href={`https://telemed-ily-web-hmg.dokku.maida.health/auth/${localStorage.getItem(
          //  href={`https://telehealth-lifeplace.maida.health/auth/${localStorage.getItem(
           href={`${process.env.REACT_APP_FRONT_ANGULAR}auth/${localStorage.getItem(
              "telehealth@token"
            )}?type=URGENCY`}
            className="btn-consulta"
          >
            Fazer consulta agora
          </a>
        </div>
      </div>
    </div>
  );
};

export default Index;
