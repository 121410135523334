const IconSuccess = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4 15.3L7.7 12.6C7.3 12.2 7.3 11.7 7.7 11.3C8.1 10.9 8.6 10.9 9 11.3L11.1 13.4L15.5 9C15.9 8.6 16.4 8.7 16.8 9C17.2 9.4 17.2 9.89999 16.8 10.3L11.8 15.3C11.4 15.7 10.8 15.7 10.4 15.3ZM21 12C21 7 17 3 12 3C7 3 3 7 3 12C3 17 7 21 12 21C17 21 21 17 21 12ZM5 12C5 8.1 8.1 5 12 5C15.9 5 19 8.1 19 12C19 15.9 15.9 19 12 19C8.1 19 5 15.9 5 12Z"
        fill="white"
      />
    </svg>
  );
};

export default IconSuccess;
