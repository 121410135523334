import  iconAlimentacao  from '../../../../assets/Union.svg'
import  health  from '../../../../assets/health.svg'
import  hydration  from '../../../../assets/hydration.svg'
import  sleep  from '../../../../assets/sleep.svg'

import { Swiper, SwiperSlide } from "swiper/react";

import { ContentCardJornada, CardItem } from "./styles"

const JourneyCard = () => {
  return (
    <ContentCardJornada >

      <Swiper slidesPerView={'auto'} spaceBetween={0} pagination={{"clickable": true}} className="mySwiper">
        <SwiperSlide>
          <CardItem style={{background: '#2B45D4'}}>
            <div className='circle-content' style={{background: '#243AB2'}}>
              <img src={iconAlimentacao} alt="" />
            </div>
            <p>Alimentação</p>
          </CardItem>
        </SwiperSlide> 

        <SwiperSlide>
          <CardItem style={{background: '#FFB000'}}>
            <div className='circle-content' style={{background: '#E59500'}}>
              <img src={health} alt="" />
            </div>
            <p>Mente</p>
          </CardItem>
        </SwiperSlide>

                  
        <SwiperSlide>
          <CardItem style={{background: '#2DA2AA'}}>
            <div className='circle-content' style={{background: '#268B91'}}>
              <img src={hydration} alt="" />
            </div>
            <p>Hidratação</p>
          </CardItem>
        </SwiperSlide>

        <SwiperSlide>
          <CardItem style={{background: '#008AD2'}}>
            <div className='circle-content' style={{background: '#0079B8'}}>
              <img src={sleep} alt="" />
            </div>
            <p>Sono</p>
          </CardItem>
        </SwiperSlide>
      </Swiper>
    </ContentCardJornada>
  )
}

export default JourneyCard;

