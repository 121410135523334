import styled  from "styled-components";

export const ContentHome = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-Content: space-between;
 
  .main {
    width: 752px;

    section:nth-child(2) {
      height: 144px;
      /* background: yellow; */
    }

    section:nth-child(3) {
      height: 270px;
    }
  }

  .schedule {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    padding-left:40px ;
    height:;
    width: 439px;
    display: flex;
    flex-direction: column;
    
    .headerConsult {
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
        color: #FF0073;
        font-weight: 600;
      }
    }
  }

  @media (max-width: 1280px) {
    padding: 0 24px;
  }


  @media(max-width:870px) {
    .schedule {
      display: none;
    }
  }
`

export const Title = styled.p `
  font-size: 18px;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 1rem;
`