import styled from 'styled-components'

export const Container = styled.div`
  /* background: #F8F8FA;
  background-color: yellow;
  width: 100vw;
  height: 100vh; */

  height: 460px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-right: 165px;

  > p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    line-height: 24px;
  }

  @media (max-width:1160px) {
    margin-right: 0;
  }
`

export const ContainerNumeros = styled.div`
  display: flex;
  margin: 2rem 0;
`

export const Titulo = styled.h1`
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  color: var(--primary);
  font-family: 'Montserrat', sans-serif;
  font-size: normal;
`

export const Texto = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--life-neutral-black-88);
  margin: 2rem 0 0.5rem 0;
`


export const Content = styled.div`
  max-width: 1366px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 1rem;

  header {
    margin-top: 48px;
  }

   .main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .information {
      height: 351px;
      max-width: 328px;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 600;
        color: var(--primary-dark);
        font-family: 'Montserrat', sans-serif;
        font-size: normal;
      }
      
      p {
        font-size: 1rem;
        font-weight: 600;

        line-height: 1.5rem;
        color: var(--life-neutral-black-64);
        margin: 2rem 0;
      }

      .btn-login {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid var(--life-neutral-black-16);
        box-sizing: border-box;
        width: 328px;
        padding: 12px 24px;
        border-radius: 8px;
        margin-bottom: 1rem;
        cursor: pointer;

        font-size: 1rem;
        color: var(--life-neutral-black-64);
        font-weight: 600;

        img {
          margin-right: 1rem;
        }
      }
    }
   } 

  @media (max-width: 700px) {
    padding: 0 14px;

    header {
      margin-top: 24px;
      margin-bottom: 6px;
    }
    .main {
      flex-direction: column;
      justify-content: center;

      .content-image {
        img {
          height: 160px;
        }
      }

      .information {
        margin-top: 38.63px;
        text-align: center;


        h1 {
          font-weight: bold;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: var(--primary-dark);
        }
      }
    }
  }
  
`


