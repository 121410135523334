const IconDanger = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99991 0C11.0999 0 11.9999 0.6 12.5999 1.5L19.5999 13.5C20.0999 14.4 20.1999 15.6 19.5999 16.5C19.0999 17.4 18.0999 18 16.9999 18H2.99991C1.89991 18 0.899902 17.4 0.399902 16.5C-0.100098 15.6 -0.100098 14.4 0.399902 13.5L7.3999 1.5C7.9999 0.6 8.89991 0 9.99991 0ZM9.19991 2.5L2.19991 14.5C1.99991 14.8 1.99991 15.2 2.19991 15.5C2.39991 15.8 2.69991 16 3.09991 16H17.0999C17.4999 16 17.7999 15.8 17.9999 15.5C18.1999 15.2 18.1999 14.8 17.9999 14.5L10.9999 2.5C10.7999 2.2 10.4999 2 10.1999 2C9.89991 2 9.29991 2.2 9.19991 2.5ZM13.1999 13.3L11.3999 11.5L13.1999 9.7C13.5999 9.3 13.5999 8.7 13.1999 8.3C12.7999 7.9 12.1999 7.9 11.7999 8.3L9.99991 10.1L8.19991 8.3C7.79991 7.9 7.19991 7.9 6.79991 8.3C6.39991 8.7 6.39991 9.3 6.79991 9.7L8.59991 11.5L6.79991 13.3C6.39991 13.7 6.39991 14.3 6.79991 14.7C7.19991 15.1 7.79991 15.1 8.19991 14.7L9.99991 12.9L11.7999 14.7C12.1999 15.1 12.7999 15.1 13.1999 14.7C13.5999 14.3 13.5999 13.7 13.1999 13.3Z"
        fill="white"
      />
    </svg>
  );
};

export default IconDanger;
