import { Routes, Route } from "react-router-dom";

// ROTAS PROTEGIGAS
import PrivateRouteAuth from "./PrivateRouteEmail";
import PrivateRoute from "./PrivateRoute";
// COMPONENTES LOGIN
import Login from "pages/Login";
import FormRight from "pages/Login/FormRight";
import FormRightEmail from "pages/Login/FormRightEmail";
import FormRightCode from "pages/Login/FormRightCode/FormRightCode";
// ONBOARDING
import Onboarding from "pages/Onboarding";
// HOME
import Home from "../pages/Home";
// import ContentItens from "pages/Home/HomeContent/Index";

// CONSULTAS
import AllConsultations from "pages/Consultations/AllConsultations/";
import { Consultations } from "pages/Consultations/";

// ERROR
import Error from "pages/Error";

const UserAuth = () => {
  return (
    <Routes>
      <Route element={<Login />}>
        <Route index element={<FormRight />} />
        <Route path="auth" element={<FormRight />} />
        <Route path="email" element={<FormRightEmail />} />

        <Route path="/" element={<PrivateRouteAuth />}>
          <Route path="code" element={<FormRightCode />} />
        </Route>
      </Route>

      <Route path="/" element={<PrivateRouteAuth />}>
        <Route path="/onboarding" element={<Onboarding ordem={true} />} />
      </Route>

      <Route path="/" element={<PrivateRoute />}>
        <Route path="home/*" element={<Home />}>
          <Route path=":noticeCode" element={<Home />} />

          <Route
            path="*"
            element={<Error ordem={false} isComponent={true} />}
          />
        </Route>
      </Route>

      <Route path="/" element={<PrivateRoute />}>
        <Route path="agendar-consulta/*" element={<Consultations />}>
          <Route path="" element={<AllConsultations />} />
          <Route
            path="*"
            element={<Error ordem={false} isComponent={true} />}
          />
        </Route>
      </Route>

      <Route path="*" element={<Error ordem={false} />}></Route>
    </Routes>
  );
};

export default UserAuth;
