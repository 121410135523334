import { Container, Content } from "./styles";
import { FooterWrapper, Container as FooterContainer } from "pages/Home/styles";
// import Footer from "pages/Home/Footer/Index";
import Header from "./Header";
import { Outlet, useLocation } from "react-router";

export const Consultations = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Container>
        <Content>
          {pathname !== "/agendar-consulta/agendar" &&
            pathname !== "/agendar-consulta/urgente" && <Header />}
          <Outlet />
        </Content>
      </Container>
      {/* style={{ marginTop: "240px" }} */}
      <FooterWrapper>
        <FooterContainer>
          {/* <Footer /> */}
        </FooterContainer>
      </FooterWrapper>
    </>
  );
};
