import ButtonBack from "components/Button/ButtonBack";
import { ReactComponent as IconBack } from "../../assets/arrowback.svg";

const Header = () => {
  const routes: any = {
    0: "/home",
    1: "/agendar-consulta",
    2: "/agendar-consulta/agendar",
    3: "/agendar-consulta/urgente",
  };

  const routesID: any = {
    "/home": 0,
    "/agendar-consulta": 1,
    "/agendar-consulta/agendar": 2,
    "/agendar-consulta/urgente": 3,
  };

  return (
    <header>
      <ButtonBack
        variant="back"
        allRoutes={routes}
        routesID={routesID}
        icon={<IconBack />}
      >
        Voltar
      </ButtonBack>
    </header>
  );
};

export default Header;
