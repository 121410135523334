import styled from 'styled-components'

export const CheckboxLife = styled.div`
  position: relative;
  display: flex;

  input[type="checkbox"] {
    display: none;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 7px;
    border: 4px solid var(--primary);
    background-color: white;
    margin-right: 12.38px;
  }

  label {
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--life-neutral-black-64);
    line-height: 1rem;
    display: grid;
    grid-template-columns: 34px 1fr;

    > p {
      margin-left: 12px;
    }

    span {
      color: var(--primary);
      font-weight: 400;
    }
  }

  input + label {
    &:before {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: var(--background);
      border: 2px solid #FF0073;
      display: inline-block;
      vertical-align: middle;
      margin-right: 12.38px;
    }
  }

  input:checked + label {
    &:before {
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='33' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%2CC296E'%3E%3Cpath fill='none' stroke='%23CC296E' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
      background-color: #fff;
      background-position: center;
    }
  }

`
