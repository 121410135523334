import React, { useState } from 'react'
import Button from '../../../components/Button/Button'
import ButtonOutline from '../../../components/Button/ButtonOutlined'
import {Container, ContainerNumeros, Texto, Titulo, } from './styles'
import InputCode from '../../../components/InputCode/InputCode'
import AlertNotification from '../../../components/Notification/Index'
import "./styles"
import { useUserContext } from 'contexts/UserContext'


const TimeToResendCode = 14 //tempo em segundos para reenvio do codigo

const FormRightCode = () => {
    
  const {userData, loading, error, validationCODE, authWithEmail} = useUserContext()

  let email:string = userData.email;  
  let format = email.split('@') //corta string
  let first = format[0] //armazena o índice 0
  let secondEmail = format[1] //armazena o índice 1
  let firstEmail = first.slice(0,3) //pega as três primeiras letras
  let emailFormatted:string = `${firstEmail}***@${secondEmail}`


  const inputRef = React.createRef<HTMLInputElement>()
  const [, setError] = useState(false)
  const [CODE, setCODE] = React.useState( {
    1: '', 
    2: '',
    3: '',
    4: ''
  })

  const [confirmCode, setConfirmCode] = React.useState(false)

  const [counter, setCounter] = React.useState(TimeToResendCode);
  
  const handleCodigo = ( pos: string, numero: string | '') => {
    setCODE({...CODE, [pos]: numero})
  }

  const handleSubmitCode = (e: React.FormEvent<HTMLFormElement>) => { 
    e.preventDefault()
    if(CODE['1'] && CODE['2'] && CODE['3'] && CODE['4']){
      const finalCode = String(CODE['1']) + String(CODE['2']) + String(CODE['3']) + String(CODE['4'])

      if(finalCode.toString().length === 4){
          validationCODE({email: userData.email, codigoVerificador: finalCode})
      }else{
        setError(true)
      }
    }
  }

  const handleResendCode = () => {
    authWithEmail(userData.email)
    setCounter(TimeToResendCode)
  }

  React.useEffect(() => {
    if(inputRef.current !== null){
      inputRef.current.focus()
    }
  }, [inputRef])

  React.useEffect(() => {
    if(CODE['1'] && CODE['2'] && CODE['3'] && CODE['4']) setConfirmCode(true)
    else setConfirmCode(false)
  }, [CODE])

  React.useEffect(() => {
    //se counter for > 0 então diminue o Timer
    const timer:any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  
  function fmtMSS(s:any) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

  return (
    <form  onSubmit={handleSubmitCode}>
      <Container>
        <Titulo>Passo 2</Titulo>
        <Texto>Agora digite o código de 4 dígitos enviado para {emailFormatted}</Texto>
        <p>
          Além da caixa de entrada, cheque também a lixeira do seu e-mail
        </p>
      
        {/* Mostra de acordo com a existencia do erro */}
        { error && <AlertNotification
          status="error"
          message="Código incorreto."
          canClose={false}/>}
          
        <ContainerNumeros>
          <InputCode refe={inputRef} pos="1" value={CODE[1]} criaCodigo={handleCodigo}/>
          <InputCode refe={CODE[1].length > 0 ? inputRef : null} pos="2" value={CODE[2]} criaCodigo={handleCodigo}/>
          <InputCode refe={CODE[2].length > 0 ? inputRef : null} pos="3" value={CODE[3]} criaCodigo={handleCodigo}/>
          <InputCode refe={CODE[3].length > 0 ? inputRef : null} pos="4" value={CODE[4]} criaCodigo={handleCodigo}/>
        </ContainerNumeros>
        
        {!loading ? <Button type="submit" variant="primary" disabled={(confirmCode) ? false : true}>Continuar</Button>
                        :
        <Button type="submit" variant="primary" disabled={true}>Carregando</Button>
        }        
        <ButtonOutline onClick={handleResendCode} disabled={counter === 0 ? false : true } 
        style={{marginTop: '8px', fontSize: '14px'}} 
        variant="outlined">Reenviar código {counter > 0 && `(${fmtMSS(counter)})`}</ButtonOutline> 
      </Container>
    </form>
  )
}

export default FormRightCode

