import { HeaderNotices } from "api/headers/HeaderNotices";
export function GET_NOTICES_TELEHEALTH(token: string) {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + "telemed/client/notice/" + token,
    options: {
      method: "GET",
      headers: HeaderNotices,
    },
  };
}

export function GET_NOTICES_AIRMED(token: string) {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + "telemed/client/notice/airmed/" + token,
    options: {
      method: "GET",
      headers: HeaderNotices,
    },
  };
}
