
const IconAjuda = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.97461 14C9.57461 14 9.97461 13.6 9.97461 13C9.97461 12.4 9.57461 12 8.97461 12C8.37461 12 7.97461 12.4 7.97461 13C7.97461 13.6 8.37461 14 8.97461 14Z" fill="#2B45D4"/>
      <path d="M18 9C18 4 14 0 9 0C4 0 0 4 0 9C0 14 4 18 9 18C14 18 18 14 18 9ZM2 9C2 5.1 5.1 2 9 2C12.9 2 16 5.1 16 9C16 12.9 12.9 16 9 16C5.1 16 2 12.9 2 9Z" fill="#2B45D4"/>
      <path d="M9.00013 9C10.1047 9 11.0001 8.10457 11.0001 7C11.0001 5.89543 10.1047 5 9.00013 5C8.44387 5 7.94065 5.22709 7.57812 5.59362" stroke="#2B45D4" stroke-width="2" stroke-linecap="round"/>
      <line x1="9" y1="9" x2="9" y2="10" stroke="#2B45D4" stroke-width="2" stroke-linecap="round"/>
    </svg>
  )
}

export default IconAjuda;